var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _vm.crd
        ? _c(
            "div",
            [
              _vm.crd.Sandbox
                ? _c(
                    "v-system-bar",
                    { attrs: { dark: "", color: "warning" } },
                    [
                      _c("span", [
                        _vm._v("Current mode is set to "),
                        _c("b", [_vm._v("SANDBOX")]),
                        _vm._v(".")
                      ]),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              icon: "",
                                              loading: _vm.loading
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.setSandbox(false)
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-swap-horizontal")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            492422244
                          )
                        },
                        [_c("span", [_vm._v("Change to production mode")])]
                      ),
                      _c("v-spacer"),
                      _c("span", [
                        _vm._v(" AccessKeyId: "),
                        _c("b", [_vm._v(_vm._s(_vm.crd.AccessKeyId))])
                      ])
                    ],
                    1
                  )
                : _c(
                    "v-system-bar",
                    { attrs: { dark: "", color: "error" } },
                    [
                      _c("span", [
                        _vm._v("Current mode is set to "),
                        _c("b", [_vm._v("PRODUCTION")]),
                        _vm._v(". Real payments can happen.")
                      ]),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              icon: "",
                                              loading: _vm.loading
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.setSandbox(true)
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-swap-horizontal")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3457339951
                          )
                        },
                        [_c("span", [_vm._v("Change to sandbox mode")])]
                      ),
                      _c("v-spacer"),
                      _c("span", [
                        _vm._v(" AccessKeyId: "),
                        _c("b", [_vm._v(_vm._s(_vm.crd.AccessKeyId))]),
                        _vm._v(" (Balance: "),
                        _c("b", [
                          _vm._v(
                            "$" +
                              _vm._s(_vm.crd.AccountBalance.AvailableBalance)
                          )
                        ]),
                        _vm._v(") ")
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        : _c(
            "div",
            [
              _c("v-system-bar", { attrs: { color: "grey lighten-2" } }, [
                _vm.loading
                  ? _c(
                      "span",
                      [
                        _vm._v(" Loading credentials... "),
                        _c("v-btn", {
                          attrs: { icon: "", disabled: "", loading: "" }
                        })
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(" No valid credential is currently set ")
                    ])
              ])
            ],
            1
          ),
      _c(
        "keep-alive",
        { attrs: { exclude: ["HIT-Create"] } },
        [
          _c("router-view", {
            attrs: {
              duct: _vm.duct,
              prjName: _vm.prjName,
              credentials: _vm.crd
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }